
import { isEqualConditionFunction, passwordComparatorValidator } from "~/utils/validators";
import { FormValidatorRuleType} from "~/utils/enums";
import { z } from 'zod';

export class Validation {
    private validationResultList: Array<IValidationResult>;
    private validationRuleList: IValidationRuleList;

    constructor(validationRuleList: IValidationRuleList) {
        if (typeof(validationRuleList) !== "undefined") {
            this.validationRuleList = validationRuleList;
        }
        else {
            this.validationRuleList = { validationRules: new Array<IValidationRule>() }; 
        }
        this.validationResultList = new Array<IValidationResult>();
    }

    validate(consolidatedFormFields: z.infer<typeof FieldInfo> | undefined | null): Array<IValidationResult> {

        let resultList: Array<IValidationResult> = new Array<IValidationResult>();
        if (typeof(this.validationRuleList) !== "undefined" && typeof(this.validationRuleList.validationRules) !== "undefined") {

            let validationRules =  this.validationRuleList.validationRules.filter((validationRule) => {
                if (validationRule.validationRuleType == FormValidatorRuleType.Validator && validationRule.isActive) {
                    return validationRule;
                };
            });
            validationRules.forEach((validationRule) => {
                this.clearValidationRuleResults(validationRule.validationRuleId);

                if (typeof(validationRule) !== "undefined" && typeof(validationRule.validators) !== "undefined") {
                    validationRule.validators.forEach((validator) => {
                        let output = validator(validationRule.sourceValue, validationRule.mandatory);
                        if (output instanceof Error && consolidatedFormFields != null) {
                            let findField = consolidatedFormFields.find(_ => _.id == validationRule.validationRuleId);
                            if (findField == null || findField.isShown == false) {
                                output = true; // If the field is not found, then we assume it is not mandatory
                            }
                        }
                        let validationResult: IValidationResult = {
                            validationRuleId: validationRule.validationRuleId,
                            result: output
                        };

                        resultList.push(validationResult);
                    });
                }
            });

            const comparatorRules  =  this.validationRuleList.validationRules.filter((comparatorRule) => {
                return  comparatorRule.validationRuleType === FormValidatorRuleType.Comparator;
            });

            if(comparatorRules.length > 0) {
                comparatorRules.forEach((comparatorRule) => {
                    if (typeof(comparatorRule.validators) !== "undefined") {

                        comparatorRule.validators.forEach((comparatorFunction) => {
                            let output = comparatorFunction(comparatorRule.sourceValue, comparatorRule.comparatorValue, comparatorRule.mandatory);

                            let validationResult: IValidationResult = {
                                validationRuleId: comparatorRule.validationRuleId,
                                result: output
                            };
                            resultList.push(validationResult);
                        });
                    }
                });
            }
        }
        this.validationResultList = resultList;
        return resultList;
    }

    // NOTE: This does not take into account fields hidden by conditional logic
    isValid(consolidatedFormFields: z.infer<typeof FieldInfo> | undefined | null = null): boolean {
        this.validate(consolidatedFormFields);

        let result  = this.status();
        return result;
    }

    status(): boolean {
        let result = false;
        
        let resultsList = this.validationResultList.filter((validationResult) => {
            if (validationResult.result instanceof Error) {
                return validationResult;
            }
        });

        if (resultsList.length === 0) {
            result = true;
        }
        return result;
    }

    getValidationRuleValidators(validationRuleId: string): Array<Function>  {
        let validators = new Array<Function>();
        let rule:IValidationRule | undefined;
        if (typeof(this.validationRuleList) !== "undefined" && typeof(this.validationRuleList.validationRules) !== "undefined" && typeof(validationRuleId) !== 'undefined') {
            rule = this.validationRuleList.validationRules.find((validationRule) => {
                return validationRule.validationRuleId === validationRuleId && validationRule.validationRuleType == FormValidatorRuleType.Validator;
            });

            if (rule !== undefined && rule.validators !== undefined) {
                validators = rule.validators;
            }
        }
        return validators;
    }

    setValidationRule(validationRuleId: string, isActive: boolean) {
        let rule: IValidationRule | undefined;
        if (typeof(this.validationRuleList) !== "undefined" && typeof(this.validationRuleList.validationRules) !== "undefined" && typeof(validationRuleId) !== 'undefined') {
            rule = this.validationRuleList.validationRules.find((validationRule) => {
                return validationRule.validationRuleId === validationRuleId && validationRule.validationRuleType == FormValidatorRuleType.Validator;
            });

            if (typeof(rule) !== 'undefined') {
                rule.isActive = isActive;
            }
        }
    }
    
    getValidationRule(validationRuleId: string) {
        let rule:IValidationRule | undefined;
        if (typeof(this.validationRuleList) !== "undefined" && typeof(this.validationRuleList.validationRules) !== "undefined" && typeof(validationRuleId) !== 'undefined') {
            rule = this.validationRuleList.validationRules.find((validationRule) => {
                return validationRule.validationRuleId === validationRuleId && validationRule.validationRuleType == FormValidatorRuleType.Validator;
            });

            if (typeof(rule) !== 'undefined') {
                return rule;
            }
            return null;
        }
    }
    setValidationRuleSourceValue(validationRuleId: string, value: string | boolean | number | Date | null | undefined) {
        let rule:IValidationRule | undefined;
        if (typeof(this.validationRuleList) !== "undefined" && typeof(this.validationRuleList.validationRules) !== "undefined" && typeof(validationRuleId) !== 'undefined') {

            rule = this.validationRuleList.validationRules.find((validationRule) => {
                if ( validationRule.validationRuleId === validationRuleId
                        && validationRule.validationRuleType === FormValidatorRuleType.Validator) {
                    return validationRule;
                }
            });

            if (typeof(rule) !== 'undefined') {
                // Set sourceValue of Validation Rule.
                rule.sourceValue = value;

                // if (rule.validationRuleType === FormValidatorRuleType.Comparator && typeof(rule.comparatorRuleId) !== 'undefined') {
                //     const comparatorRule = this.getValidationRule(rule.comparatorRuleId);
                //     if (comparatorRule != null && typeof(comparatorRule) !== 'undefined') {
                //         rule.comparatorValue = comparatorRule.sourceValue;
                //         comparatorRule.comparatorValue = value;
                //     }
                // }

                //ConditionalComparator Rules
                let conditionalRules: Array<IValidationRule> = this.validationRuleList.validationRules.filter((rule) => {
                    if(rule.validationRuleType === FormValidatorRuleType.ConditionalComparator && rule.comparatorRuleId === validationRuleId) {
                        return rule;
                    }
                });
                if(conditionalRules.length > 0) {
                    conditionalRules.forEach((conditionalRule) => {
                        conditionalRule.comparatorValue = value;

                    });
                }
            }
        }

        // Get ComparatorRules whose ComparatorRuleId ==  current ValidationRuleId
        let comparatorRulesForComparator = this.validationRuleList.validationRules.filter((rule) => {
            if ( rule.comparatorRuleId === validationRuleId
                    && rule.validationRuleType === FormValidatorRuleType.Comparator) {
                return rule;
            }
        });

        if(comparatorRulesForComparator.length > 0){
            comparatorRulesForComparator.forEach((comparatorRule) => {
                // rule.comparatorValue = comparatorRule.sourceValue;
                comparatorRule.comparatorValue = value;
            });
        }

           //Get the ComparatorRules for the  current ValidationRuleId
        let comparatorRulesForValidator = this.validationRuleList.validationRules.filter((rule) => {
            if ( rule.validationRuleId === validationRuleId
                    && rule.validationRuleType === FormValidatorRuleType.Comparator) {
                return rule;
            }
        });

        if(comparatorRulesForValidator.length > 0){
            comparatorRulesForValidator.forEach((comparatorRule) => {
                // rule.comparatorValue = comparatorRule.sourceValue;
                comparatorRule.sourceValue = value;
            });
        }
    }

    validateRule(validationRuleId: string):  Array<IValidationResult>{
        let resultList: Array<IValidationResult> = new Array<IValidationResult>();

        if (typeof(validationRuleId) !== 'undefined') {

            this.clearValidationRuleResults(validationRuleId);

            if (typeof(this.validationRuleList) !== "undefined" && typeof(this.validationRuleList.validationRules) !== "undefined") {

                //Get Validation Rule
                const existingValidatorRule = this.validationRuleList.validationRules.find((validationRule) => {
                    if (validationRule.validationRuleId === validationRuleId 
                            && validationRule.validationRuleType === FormValidatorRuleType.Validator 
                            && validationRule.isActive) {
                        return validationRule;
                    };
                });

                if (typeof(existingValidatorRule) !== 'undefined') {
                   
                   // Default Validator
                    if (typeof(existingValidatorRule.validators) !== "undefined") {
                        existingValidatorRule.validators.forEach((validator) => {
                            let output= validator(existingValidatorRule.sourceValue, existingValidatorRule.mandatory);
                            
                            let validationResult: IValidationResult = {
                                validationRuleId: existingValidatorRule.validationRuleId,
                                result: output
                            };
                            resultList.push(validationResult);
                        });
                    }

                     // BF need to lookup the validationRuleId in the other rules. 
                    let conditionalComparatorRules = this.validationRuleList.validationRules.filter((rule) => {
                        if (    rule.validationRuleType === FormValidatorRuleType.ConditionalComparator 
                                && rule.comparatorRuleId === validationRuleId) {
                            return rule; 
                         }
                    });

                    if(conditionalComparatorRules.length > 0){ 
                        conditionalComparatorRules.forEach((conditionalRule) => {
                          
                            let relatedInteractionRules: Array<IValidationRule> = [];
                            // Get the Interaction Rule
                            if(typeof(conditionalRule.interactionRuleId) !== 'undefined' && conditionalRule.interactionRuleId != null) {
                                    relatedInteractionRules = this.validationRuleList.validationRules.filter((rule) => {
                                    if (    rule.validationRuleType === FormValidatorRuleType.Interaction 
                                            && rule.validationRuleId === conditionalRule.interactionRuleId) {
                                        return rule; 
                                    }
                                });
                            }

                            // Evaluate the Conditional Comparator function of the Validation Rule
                            let conditionResult = false;
                            if (conditionalRule.sourceValue !== undefined) {
                                conditionResult = isEqualConditionFunction(
                                    conditionalRule.validationRuleId, 
                                    conditionalRule.sourceValue, 
                                    conditionalRule.conditionalValue,
                                    conditionalRule.comparatorRuleId,
                                    conditionalRule.comparatorValue,
                                    conditionalRule.interactionRuleId
                                );
                            }

                            if (conditionResult) {
                                if(relatedInteractionRules.length > 0) {
                                    // Should only be 1 interaction
                                    relatedInteractionRules.forEach((interactionRule) => {
                                        if (typeof(interactionRule.interactionTrue) !== 'undefined' && interactionRule.interactionTrue != null) {
                                            interactionRule.interactionTrue(interactionRule.interactionElement);
                                        }
                                    });
                                }
                            } else {
                                if(relatedInteractionRules.length > 0) {
                                    // Should only be 1 interaction
                                    relatedInteractionRules.forEach((interactionRule) => {
                                        if (typeof(interactionRule.interactionFalse) !== 'undefined' && interactionRule.interactionFalse != null) {
                                            interactionRule.interactionFalse(interactionRule.interactionElement);
                                        }
                                    });
                                }
                            }
                          
                        });
                    }

                    //  let relatedInteractionRules = this.validationRuleList.validationRules.filter((rule) => {
                    //     if (rule.validationRuleType === FormValidatorRuleType.Interaction && rule.comparatorRuleId === validationRuleId) {
                    //        return rule; 
                    //     }
                    // });

                    // if(relatedInteractionRules.length > 0){
                    //     relatedInteractionRules.forEach((interactionRule) => {
                    //         if (typeof(interactionRule.interaction) !== 'undefined' && interactionRule.interaction != null) {
                    //             interactionRule.interaction(interactionRule.interactionElement);
                    //         }
                    //     });
                    // }
                }

                //Get Comparator Rules
                const existingComparatorRules  =  this.validationRuleList.validationRules.filter((comparatorRule) => {
                    if ( comparatorRule.validationRuleId === validationRuleId
                            && comparatorRule.validationRuleType === FormValidatorRuleType.Comparator) {
                        return comparatorRule;
                    }
                });

                if(existingComparatorRules.length > 0) {
                    existingComparatorRules.forEach((comparatorRule) => {

                        if (typeof(comparatorRule.validators) !== "undefined") {

                            comparatorRule.validators.forEach((comparatorFunction) => {
                                let output = comparatorFunction(comparatorRule.sourceValue, comparatorRule.comparatorValue, comparatorRule.mandatory);
    
                                let validationResult: IValidationResult = {
                                    validationRuleId: comparatorRule.validationRuleId,
                                    result: output
                                };
                                resultList.push(validationResult);
                            });
                        }
                    });
                }
                
            }
            if (resultList.length > 0) {
                resultList.forEach((validationResult) => {
                    this.validationResultList.push(validationResult);
                });
            }
            
        }
        
        return resultList;
    }

    isValidationRuleValid(validationRuleId: string): boolean {
        let isValid = false;
        if (typeof(validationRuleId) !== 'undefined') {


            let resultsList = this.validationResultList.filter((validationResult) => {
                if (validationResult.result instanceof Error && validationResult.validationRuleId === validationRuleId) {
                    return validationResult;
                }
            });
    
            if (resultsList.length === 0) {
                isValid = true;
            }
            
        }

        return isValid;
    }

    clearValidationRuleResults(validationRuleId: string) {
        if (typeof(validationRuleId) !== 'undefined') {

            let resultsList = this.validationResultList.filter((validationResult) => {
                if (validationResult.result instanceof Error && validationResult.validationRuleId === validationRuleId) {
                    return validationResult;
                }
            });
    
            if (resultsList.length > 0) {
                resultsList.forEach((validationResult) => {
                    let deleteIndex = this.validationResultList.indexOf(validationResult);
                    if (deleteIndex > -1){
                        this.validationResultList.splice(deleteIndex, 1);
                    }
                });
            }
            
        }
    }

    getValidationResultString(validationRuleId: string): string {
        let output = '';

        if (typeof(validationRuleId) !== 'undefined') {
            let outputErrors = new Array();
            this.validationResultList.forEach((validationResult) => {
                if(validationResult.result instanceof Error && validationResult.validationRuleId === validationRuleId) {
                    let result: Error = validationResult.result;
                    let hasExistingError = outputErrors.find((outputError) => {
                        
                        return (outputError.message == result.message && outputError.name == result.name);
                    });

                    if (typeof(hasExistingError) === 'undefined'){
                        outputErrors.push(result);
                    }
                }
            });

            if(outputErrors.length > 0) {
                let outputDelimited = new Array();
                outputErrors.forEach((error) => {
                    outputDelimited.push(error.message);
                });
                output = outputDelimited.join(", ");
            }
        }
        return output;
    }
}
